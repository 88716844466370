<template>
  <div>
    <div
      class="d-flex"
      style="    min-height: 80px;
    align-items: center;"
    >
      <div class="text-primary font-size-h4 font-weight-bolder">
        {{ $t("DASHBOARD.DOCTOR.today.title") }}
      </div>
    </div>
    <v-card class="p-0 m-0 rounded-lg" flat>
      <v-timeline align-top dense v-if="apps.length != 0">
        <v-timeline-item
          color="primary"
          small
          dark
          fill-dot
          v-for="item in apps"
          :key="item.id"
        >
          <template v-slot:icon>
            <v-avatar>
              <v-icon color="white" size="10px">{{
                item.type == "video" ? "fas fa-video" : "fas fa-user-friends"
              }}</v-icon>
            </v-avatar>
          </template>
          <v-row class="py-0 my-0">
            <v-col class="py-0 my-0" cols="3">
              {{
                (item.date_time + "Z")
                  | moment("timezone", $util.getTimezone(), "hh:mm A")
              }}
            </v-col>
            <v-col class="py-0 my-0" cols="9">
              {{ item.type }} {{ $t("DASHBOARD.DOCTOR.today.with") }}
              {{ item.owner.first_name }}
              {{ item.owner.last_name }}
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
      <div
        style="display:flex;min-height: 20vh;justify-content: center;align-items: center;"
        v-else
      >
        {{ $t("DASHBOARD.DOCTOR.today.none") }}
      </div>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
//import Avatar from "@/view/pages/utilComponents/avatar2.vue";

export default {
  name: "TodayTimeline",
  //props: ["mfid"],
  //components: {Avatar},
  data() {
    return {
      error: "",
      apps: [],
      isDoneLoading: false
    };
  },
  mounted() {
    this.getTodaysAppointments();
  },
  methods: {
    async getTodaysAppointments() {
      this.$moment.locale("en");
      var userInfo = JSON.parse(localStorage.userInfo).id;
      await ApiService.get(
        "items",
        "appointments?fields=id,status,video_room_id,rating,type,date,time,date_time,owner.id,owner.first_name,owner.last_name,owner.avatar.data,medical_file.id,medical_file.birthday,medical_file.gender,rating&filter[doctor.doctor_id.id]=" +
          userInfo +
          "&filter[date_time][contains]=" +
          this.$moment().format("YYYY-MM-DD") +
          "&sort=date_time"
      )
        .then(({ data }) => {
          //console.log(data);
          this.apps = data.data;
          this.isDoneLoading = true;
          this.$moment.locale(localStorage.language);
        })
        .catch(error => {
          this.error = error;
        });
    }
  },
  computed: {}
};
</script>
<style>
.v-timeline-item__dot {
  box-shadow: none !important;
}
</style>
