<template>
  <v-row :class="$vuetify.breakpoint.mobile ? 'mb-15' : ''">
    <v-col cols="12" class="pb-0 mb-0">
      <BoxedStats />
    </v-col>
    <v-col cols="12" sm="12" md="3" lg="3" class="py-0 my-0">
      <Timeline />
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="9"
      lg="9"
      :class="$vuetify.breakpoint.mobile ? 'pt-0' : 'py-0'"
      class=" my-0"
    >
      <MonthStats></MonthStats>
    </v-col>
  </v-row>
</template>

<script>
//import Avatar from "@/view/pages/utilComponents/avatar2.vue";
import MonthStats from "./comp/monthStats.vue";
import BoxedStats from "./comp/statBoxes.vue";
import Timeline from "./comp/todayApps.vue";

export default {
  name: "DoctorDashboard",
  //props: ["mfid","dialog"],
  components: { MonthStats, BoxedStats, Timeline },
  data() {
    return {
      error: "",
      userInfo: null,
      isProfileComplete: true
    };
  },
  mounted() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
  },
  methods: {
    toast(toaster, v, msg, append = false) {
      this.counter++;
      this.$bvToast.toast(msg, {
        title: `Alert`,
        toaster: toaster,
        solid: true,
        variant: v,
        appendToast: append
      });
    }
  },
  computed: {}
};
</script>
