<template>
  <div class="">
    <div
      class="d-flex"
      style="    min-height: 80px;
    align-items: center;"
    >
      <div class="text-primary font-size-h4 font-weight-bolder">
        {{ $t("DASHBOARD.DOCTOR.stats.monthstats") }}
      </div>
      <v-spacer />
      <v-spacer v-if="!$root.mobile" />

      <v-dialog ref="dialog" v-model="modal" width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateString"
            prepend-inner-icon="mdi-calendar"
            readonly
            dense
            rounded
            filled
            hide-details
            single-line
            v-bind="attrs"
            background-color="blue lighten-4"
            class="primary--text"
            v-on="on"
            style="width:0px"
            v-if="!$root.mobile"
          ></v-text-field>
          <v-btn
            v-bind="attrs"
            v-on="on"
            v-else
            fab
            small
            elevation="0"
            dark
            color="primary"
          >
            <v-icon color="">mdi-calendar</v-icon>
          </v-btn>
        </template>
        <v-date-picker v-model="date" scrollable type="month">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="modal = false">
            {{ $t("POPUPS.cancel") }}
          </v-btn>
          <v-btn text color="primary" @click="getMonthStats">
            {{ $t("POPUPS.okay") }}
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </div>
    <v-card class="p-0 m-0 rounded-lg" style="min-height:300px" flat>
      <v-tabs :show-arrows="true" v-if="$root.mobile" fixed-tabs v-model="page">
        <v-tab v-for="n in 5" :key="n">Week {{ n }}</v-tab>
      </v-tabs>
      <div id="chart" v-if="!this.$root.mobile">
        <apexchart
          v-if="isDoneLoading"
          :key="date"
          type="bar"
          height="350"
          :options="chartOptions"
          :series="stats"
        ></apexchart>
        <div
          v-if="!isDoneLoading"
          class="text-center mt-5 centercontent"
          style="min-height:250px"
        >
          <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
        </div>
      </div>

      <div id="chart-week" v-else>
        <template v-if="isDoneLoading">
          <apexchart
            :key="page"
            type="bar"
            height="350"
            :options="chartWeekOptions"
            :series="weekStats"
          ></apexchart>
        </template>

        <div
          v-else
          class="text-center mt-5 centercontent"
          style="min-height:366px"
        >
          <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
//import Avatar from "@/view/pages/utilComponents/avatar2.vue";

export default {
  name: "MonthStats",
  //props: ["mfid"],
  //components: {Avatar},
  data() {
    return {
      error: "",
      stats: [],
      weekStats: [],
      avail: [],
      page: 0,
      date: new Date().toISOString().substr(0, 10),
      modal: false,
      isDoneLoading: false,
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: false
          }
        },
        grid: {
          show: false // you can either change hear to disable all grids
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "30%",
            borderRadius: 5
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 0.5,
          colors: ["transparent"]
        },
        xaxis: {
          categories: [],
          labels: {
            show: false,
            rotate: -90,
            rotateAlways: true
          }
        },

        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val + " appointment";
            }
          }
        }
      },
      chartWeekOptions: {
        chart: {
          type: "bar",
          stacked: true,
          height: 350,
          toolbar: {
            show: false
          },
          sparkline: {
            enabled: false
          }
        },
        grid: {
          show: false // you can either change hear to disable all grids
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "30%",
            borderRadius: 5
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: true
          }
        },
        yaxis: {},
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val + " appointment";
            }
          }
        }
      }
    };
  },
  mounted() {
    this.$moment.locale("en");
    this.page = Math.ceil((new Date().getDate() - 1 - new Date().getDay()) / 7);
    !this.$root.mobile && this.getStatus(this.$moment().format("/YYYY/MM"));
    this.$root.mobile && this.setPagination();
  },
  watch: {
    page() {
      this.setPagination();
    },
    date() {
      this.avail = [];
    }
  },
  methods: {
    async getStatus(date) {
      console.log("date", date.split("/"));
      await this.$util
        .getDS()
        .rpc.make("getDoctorSlots", {
          id: this.$doctor.getCurrentDoctor().id,
          year: date.split("/")[1],
          month: date.split("/")[2],
          availability: this.$doctor.getCurrentDoctor().availability,
          days_off: this.$doctor.getCurrentDoctor().days_off,
          duration: this.$doctor.getCurrentDoctor().call_duration
            ? this.$doctor.getCurrentDoctor().call_duration
            : this.$util.getSettings().call_duration
        })
        .then(res => {
          //console.log("res from ds", res);

          this.$nextTick(() => {
            this.stats = [];
            this.chartOptions.xaxis.categories = res.map(item => {
              return item.date;
            });
            console.log("cat", this.chartOptions.xaxis.categories);
            this.stats.push({
              name: this.$t("DASHBOARD.DOCTOR.stats.booked"),
              data: res.map(item => {
                return item.booked;
              })
            });
            this.stats.push({
              name: this.$t("DASHBOARD.DOCTOR.stats.avail"),
              data: res.map(item => {
                return item.available;
              })
            });

            this.isDoneLoading = true;
          });
        });
    },

    async getWeekStatus(date, start = 0, end = 7) {
      try {
        console.log("date", date, start, end);
        this.isDoneLoading = false;
        if (this.avail.length === 0) {
          console.log("avail is zero");
          let res = await this.$util.getDS().rpc.make("getDoctorSlots", {
            id: this.$doctor.getCurrentDoctor().id,
            year: date.split("/")[1],
            month: date.split("/")[2],
            availability: this.$doctor.getCurrentDoctor().availability,
            days_off: this.$doctor.getCurrentDoctor().days_off,
            duration: this.$doctor.getCurrentDoctor().call_duration
              ? this.$doctor.getCurrentDoctor().call_duration
              : this.$util.getSettings().call_duration
          });
          this.avail = res;
          console.log("got avail and it is", this.avail);
        }

        this.weekStats = [];
        var avail = [];
        var booked = [];
        var dates = [];

        !end && (end = this.avail.length);
        console.log("date before day loop", date, start, end);
        for (let item of this.avail.slice(start, end)) {
          booked.push(item["booked"]);

          if (item["available"] == -1) {
            avail.push(0);
          } else {
            avail.push(item["available"]);
          }
          var d = item["date"].split("-");
          dates.push(d[1] + "/" + d[2]);
        }
        console.log("data after loop", avail, booked, dates);
        this.chartWeekOptions.xaxis.categories = dates;
        this.weekStats.push({
          name: this.$t("DASHBOARD.DOCTOR.stats.booked"),
          data: booked
        });
        this.weekStats.push({
          name: this.$t("DASHBOARD.DOCTOR.stats.avail"),
          data: avail
        });

        // console.log(this.weekStats);
        this.isDoneLoading = true;
      } catch (error) {
        console.log("error", error);
      }
    },
    async getMonthStats() {
      //$refs.dialog.save(this.date);
      // console.log("new date is: ", this.date);
      this.modal = false;
      this.isDoneLoading = false;
      this.$moment.locale("en");
      this.$vuetify.breakpoint.mobile
        ? this.setPagination()
        : this.getStatus(this.$moment(this.date).format("/YYYY/MM"));
    },

    setPagination() {
      let start, end;
      switch (this.page) {
        case 0:
          start = 0;
          end = 7;
          break;

        case 1:
          start = 7;
          end = 14;
          break;

        case 2:
          start = 14;
          end = 21;
          break;

        case 3:
          start = 21;
          end = 28;
          break;

        case 4:
          start = 28;
          end = null;
          break;

        default:
          break;
      }

      this.getWeekStatus(
        this.$moment(this.date).format("/YYYY/MM"),
        start,
        end
      );
    }
  },
  computed: {
    dateString() {
      this.$moment.locale("en");
      return this.$moment(this.date).format("MMMM");
    }
  }
};
</script>
<style scoped>
.theme--light >>> .v-slide-group__prev >>> .v-slide-group__prev--disabled {
  display: none !important;
}
</style>
