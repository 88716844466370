<template>
  <div>
    <h4 class="text-primary font-weight-bolder px-1 pb-2">
      Overview
    </h4>
    <v-row
      v-if="isDoneLoading"
      class="mb-2 p-3 pushxl hidescroll"
      style="flex-wrap: nowrap;"
    >
      <template v-for="(item, index) in stats">
        <div
          class="userprofile_box white--text listitem p-4 py-4 primary lighten-1 pushxl"
          :style="
            $root.mobile ? 'width: 55vw;min-width: 55vw;' : 'min-width:270px'
          "
          :key="index"
        >
          <div class="inner pushxl">
            <unicon
              :name="item.icon"
              fill="#FFFFFF"
              height="20"
              class="pushx"
            ></unicon>
            <span>{{ item.name }}</span>
          </div>
          <v-spacer /><span class="font-weight-black text-h5">{{
            item.stat
          }}</span>
        </div>
      </template>
    </v-row>
    <v-row v-else class="mb-2 p-3 pushxl hidescroll" style="flex-wrap: nowrap;">
      <div
        v-for="i in 5"
        :key="i"
        class=" skeleton-box  p-4 py-4   pushxl"
        :style="
          $root.mobile ? 'width: 55vw;min-width: 55vw;' : 'min-width:270px;'
        "
        style="55vw; min-height:68px;border-radius: 10px;"
      ></div>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
//import Avatar from "@/view/pages/utilComponents/avatar2.vue";

export default {
  name: "statBoxes",
  //props: ["mfid"],
  //components: {Avatar},
  data() {
    return {
      error: "",
      stats: [],
      isDoneLoading: false,
      statitle: [
        { name: this.$t("DASHBOARD.DOCTOR.stats.total"), icon: "calender" },
        { name: this.$t("DASHBOARD.DOCTOR.stats.video"), icon: "video" },
        { name: this.$t("DASHBOARD.DOCTOR.stats.clinic"), icon: "hospital" },
        {
          name: this.$t("DASHBOARD.DOCTOR.stats.cons"),
          icon: "exclamation-circle"
        },
        { name: this.$t("DASHBOARD.DOCTOR.stats.given"), icon: "check-circle" },
        { name: this.$t("DASHBOARD.DOCTOR.stats.ques"), icon: "comment-redo" }
      ]
    };
  },
  mounted() {
    this.getStats();
  },
  methods: {
    async getStats() {
      //var userInfo = JSON.parse(localStorage.userInfo).id;
      await ApiService.get("custom", "stats/doctor/" + this.$root.docID)
        .then(({ data }) => {
          // console.log("status", data.data);
          this.stats = data.data.map((i, index) => {
            return {
              name: this.statitle[index].name,
              stat: i.stat,
              icon: this.statitle[index].icon
            };
          });
          // console.log("status", this.stats);
          this.isDoneLoading = true;
        })
        .catch(error => {
          this.error = error;
        });
    }
  }
};
</script>
<style lang="scss">
.skeleton-box {
  height: 68px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #dddbdd;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
